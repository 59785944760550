<template lang="pug">

v-container(fluid).doradociard
  v-row
    v-img(  :src='disputas_encabezado.imagen' height='76vh' ).d-none.d-md-flex
      v-container.fill-height
        v-row.justify-center.align-center
          v-col(cols=12 md=5  style='background-color: rgba(0, 0, 0, .3); margin-bottom: 1em').justify-center.align-center
            h2.font-weight-bold.pb-2.text-uppercase.white--text(v-html='disputas_encabezado.título').text-center
    
    
    v-img(  :src='disputas_encabezado.imagen' height='300px' width='100%').d-md-none
      v-container.d-md-none
        v-row(  justify='center')
          v-col( align='center' cols=9 style='background-color: rgba(0, 0, 0, .3);margin-top: 6em ').d-md-none
            h3.font-weight-bold.pb-2.text-uppercase.white--text(v-html='disputas_encabezado.título').justify-center
    

   
  v-row.doradociard.justify-center.align-center
    v-col(cols=11 md=7 style="margin-bottom: 3em; margin-top: 3em")
      v-img(  :src='nosotros.ventana' height='100px' contain ).d-none.d-md-block
      v-img(  :src='nosotros.ventana' height='60px' contain ).d-md-none
      p.text-justify.font-weight-light.mb-3.mt-5( v-html='disputas_encabezado.contenido').d-none.d-md-block
      p.text-caption.text-justify.font-weight-light.mb-3.mt-5( v-html='disputas_encabezado.contenido').d-md-none


  
    

</template>
<script>
export default {
  components: {
  }
}
</script>
<style lang="sass">
.link-normativa
  text-decoration: none
</style>